import "react-resizable/css/styles.css";
import "./resizable.css";

import React, { CSSProperties, useCallback } from "react";
import { ResizableBox, ResizeCallbackData } from "react-resizable";
import styled from "styled-components";
import { useWindowSize } from "usehooks-ts";

import { useQuery } from "@apollo/client";
import ArrowUpDD from "@img/arrow_light_up.svg";
import { BottomPanel } from "@src/Components/BottomPanel";
import { Clickable } from "@src/Components/Buttons/Clickable";
import { H3 } from "@src/Components/Text";
import { FetchPanelLogsQuery, FetchPanelLogsQueryVariables } from "@src/generated/graphql";
import { useFilterByFields } from "@src/Hooks/filterByFields";
import { useToggle } from "@src/Hooks/toggle";
import { AuditLogNode } from "@src/Reports/fetchLogs";

import FetchPanelLogs from "./FetchPanelLogs.graphql";
import { LogsFilter, logTypeFilters } from "./LogsFilter";
import { panelHeightKey } from "./panelHeightKey";
import { PanelLogsTable } from "./PanelLogsTable";

const PanelWrapper = styled.div`
  width: fit-content;
  position: sticky;
  bottom: 0;
  justify-self: flex-end;
  margin-right: 5%;
  grid-column: 2;
`;

const OpenPanelButton = styled(Clickable)`
  background-color: white;
  font-size: medium;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.25);
  gap: 12px;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  overflow: auto;
`;

const PanelTopWrapper = styled.div`
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ccc;
  z-index: 1;
`;

const FirstLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px 10px 40px;
  box-sizing: border-box;
  background-color: white;
  border-bottom: 1px solid #ccc;
`;

const Cross = styled.span`
  cursor: pointer;
  font-size: 20px;
`;

const PanelTitle = styled(H3)`
  margin: 0;
`;

const resizableBoxStyles: CSSProperties = {
  backgroundColor: "white",
  gridArea: "panel",
  position: "sticky",
  bottom: 0,
  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.25)"
};

export function LogsPanel() {
  const { state, on: open, off: close } = useToggle();

  return (
    <>
      {!state && (
        <PanelWrapper>
          <OpenPanelButton onClick={open}>
            Audit Logs <ArrowUpDD height={20} width={20} />
          </OpenPanelButton>
        </PanelWrapper>
      )}
      <BottomPanel show={state}>
        <LogsPanelInner close={close} />
      </BottomPanel>
    </>
  );
}

export const pollInterval = 10000;
interface LogsPanelInnerProps {
  close: () => void;
}

function LogsPanelInner({ close }: LogsPanelInnerProps) {
  const { data, loading } = useQuery<FetchPanelLogsQuery, FetchPanelLogsQueryVariables>(
    FetchPanelLogs,
    {
      errorPolicy: "all",
      fetchPolicy: "network-only",
      pollInterval: pollInterval,
      variables: {
        startTime: "now-10m",
        endTime: "now",
        pageSize: 10
      }
    }
  );

  const handleResize = useCallback(
    (e: never, data: ResizeCallbackData) => {
      const height = data.size.height;
      if (height < 200) close();
    },
    [close]
  );

  const saveHeightValue = useCallback((e: never, data: ResizeCallbackData) => {
    const height = data.size.height;
    localStorage.setItem(panelHeightKey, JSON.stringify(height));
  }, []);

  const { selectedFilters, toggleFilterTag } = useFilterByFields(logTypeFilters);
  const logs: AuditLogNode[] = (data?.auditLogs?.edges || []).map(({ node }) => node);
  const selectedLogs = logs.filter(log => selectedFilters.includes(log?.type));

  const { height: windowHeight } = useWindowSize();
  const panelHeight = JSON.parse(localStorage.getItem(panelHeightKey)) || 350;

  return (
    <ResizableBox
      width={Infinity}
      height={panelHeight <= windowHeight ? panelHeight : windowHeight}
      resizeHandles={["n"]}
      axis="y"
      style={resizableBoxStyles}
      onResize={handleResize}
      onResizeStop={saveHeightValue}
    >
      <Wrapper>
        <PanelTopWrapper>
          <FirstLine>
            <PanelTitle>Recent Logs</PanelTitle>
            <Cross title="close" onClick={close}>
              ✕
            </Cross>
          </FirstLine>
          <LogsFilter
            logs={logs}
            selectedFilters={selectedFilters}
            toggleFilterTag={toggleFilterTag}
            filtersPadding="6px 10px"
          />
        </PanelTopWrapper>
        <PanelLogsTable logs={selectedLogs} loading={loading} />
      </Wrapper>
    </ResizableBox>
  );
}
