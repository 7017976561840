import React, { ReactNode } from "react";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components";

const transitionName = "panelUp";

const Animation = styled.div`
  position: sticky;
  bottom: 0;
  grid-area: panel;

  &.${transitionName}-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  &.${transitionName}-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: opacity 200ms, transform 300ms ease-out;
  }
  &.${transitionName}-exit {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  &.${transitionName}-exit-active {
    opacity: 1;
    transform: translate3d(0, 100%, 0);
    transition: opacity 200ms, transform 300ms ease-in;
  }
`;

interface BottomPanelProps {
  show: boolean;
  children: ReactNode;
}

export function BottomPanel({ show, children }: BottomPanelProps) {
  return (
    <CSSTransition in={show} timeout={200} appear={true} unmountOnExit classNames={transitionName}>
      <Animation>{children}</Animation>
    </CSSTransition>
  );
}
