import { ApolloError } from "@apollo/client";
import { Org, UserOrgsQuery } from "@src/generated/graphql";

export function filterValidOrgs(data: UserOrgsQuery, error: ApolloError): Org[] {
  const orgRoleErrorIndex = (error?.graphQLErrors || []).map(err => {
    return err.path[2] as number;
  });

  const userOrgs = (data?.user?.orgRoles || [])
    .map(rol => rol?.org)
    .filter((org, index) => !orgRoleErrorIndex.includes(index) && !!org.id && !!org.displayName);

  return userOrgs;
}
