import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { GhostButton } from "@src/Components/Buttons/Ghost";

import { H1, H2 } from "../Components/Text";

const ErrorStack = styled.pre`
  white-space: pre-wrap;
`;

interface CustomError extends Error {
  status?: number;
}

interface ErrorPageProps {
  error: CustomError;
}

export function ErrorPage({ error }: ErrorPageProps) {
  return (
    <div>
      <H1>{error.message}</H1>
      <H2>{error?.status && error.status}</H2>
      <ErrorStack>{error.stack}</ErrorStack>
    </div>
  );
}

export const requestErrorKey = "requestErrorKey";

const PageWrapper = styled.div`
  padding: 100px;
`;

const GoBackButton = styled(GhostButton)`
  margin-top: 20px;
`;

export function ErrorReviewPage() {
  const error = JSON.parse(window.localStorage.getItem(requestErrorKey)) as Error;
  const navigate = useNavigate();

  useEffect(() => {
    if (!error) navigate(-1);
  }, [error, navigate]);

  if (!error) return null;
  return (
    <PageWrapper>
      <ErrorPage error={error} />
      <GoBackButton onClick={() => navigate(-1)}>Go Back</GoBackButton>
    </PageWrapper>
  );
}
