import { useQuery } from "@apollo/client";
import { UserOrgsQuery } from "@src/generated/graphql";
import UserOrgs from "@src/User/UserOrgs.graphql";

import { filterValidOrgs } from "./userOrgFilter";

export function useUserOrgs() {
  const { data, error, loading } = useQuery<UserOrgsQuery>(UserOrgs, {
    errorPolicy: "all"
  });

  const userOrgs = filterValidOrgs(data, error);

  return {
    userOrgs,
    loading
  };
}
