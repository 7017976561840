import React from "react";
import styled from "styled-components";

import { Skeleton } from "@src/Components/Skeleton";

import { DeviceStatus, Status } from "../generated/graphql";

interface StatusTagProps {
  status: Status;
}

export const Tag = styled.span`
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 16px;
  border-radius: 10px;
  background-color: #ffffff;
  white-space: nowrap;
`;

const Progressing = styled(Tag)`
  border: solid 1px ${({ theme }) => theme.success};
  color: ${({ theme }) => theme.success};
`;

const Deleting = Progressing;

const Provisioning = styled(Tag)`
  border: solid 1px ${({ theme }) => theme.lightblue};
  color: ${({ theme }) => theme.lightblue};
`;

const InSync = styled(Tag)`
  background-color: ${({ theme }) => theme.success};
  color: white;
`;

const Error = styled(Tag)`
  background-color: ${({ theme }) => theme.failure};
  color: white;
`;

const Unknown = styled(Tag)`
  border: 1px solid ${({ theme }) => theme.grey};
  color: #ccc;
`;

const Pending = Unknown;

export function StatusTag({ status }: StatusTagProps) {
  switch (status) {
    case Status.Progressing:
      return <Progressing>progressing</Progressing>;
    case Status.InSync:
      return <InSync>in sync</InSync>;
    case Status.Deleting:
      return <Deleting>deleting</Deleting>;
    case Status.Error:
      return <Error>error</Error>;
    case Status.Pending:
      return <Pending>pending</Pending>;
    case Status.Unknown:
    default:
      return <Unknown>unknown</Unknown>;
  }
}

export function SkeletonBadge() {
  return <Skeleton width={75} height={20} borderRadius="10px" style={{ lineHeight: "initial" }} />;
}

interface DeviceStatusTagProps {
  status: DeviceStatus;
}

const Ready = InSync;

export function DeviceStatusTag({ status }: DeviceStatusTagProps) {
  switch (status) {
    case DeviceStatus.Deploying:
      return <Provisioning>provisioning</Provisioning>;
    case DeviceStatus.Ready:
      return <Ready>ready</Ready>;
    case DeviceStatus.Error:
      return <Error>error</Error>;
    case DeviceStatus.Unknown:
    default:
      return <Unknown>unknown</Unknown>;
  }
}
